import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import {getIconComponentByType} from "../../utils/assets";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import {useContext} from "react";
import {DrawToolContext} from "../../utils/layers/draw/DrawToolContext";
import SmartTrackingCreateOrEdit from "../../components/SmartTracking/CreateOrEdit";
import { useNavigate } from "react-router-dom";

const SmartTrackingAddPage = () => {
  const drawTool = useContext(DrawToolContext);
  const navigate = useNavigate();
  
  const handleBackClick = () => {
    drawTool.finishGeometry()
    navigate("/smart-tracking")
  }
  return (
    <Box pt={2}>
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <IconButton
            onClick={handleBackClick}
          >
            {getIconComponentByType("keyboardBack")}
          </IconButton>
        </Grid>
        <Grid item container xs={10} justifyContent="center">
          <Typography variant="h5">Add Polygon</Typography>
        </Grid>
      </Grid>
      <Divider/>
      <SmartTrackingCreateOrEdit drawTool={drawTool}/>
    </Box>
  )
}

export default SmartTrackingAddPage