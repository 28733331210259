import { Route, Routes } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

import LoginPage from "../pages/LoginPage";
import SignupPage from "../pages/SignupPage";

import ProjectPage from "../pages/ProjectPage";
import FlightPage from "../pages/FlightPage";
import FlightAddPage from "../pages/FlightAddPage";
import SeePage from "../pages/SeePage";
import MaterialPage from "../pages/MaterialPage";
import MaterialAddPage from "../pages/MaterialAddPage";
import MaterialEditPage from "../pages/MaterialEditPage";
import ExportPage from "../pages/ExportPage";
import DesignListPage from "../pages/DesignListPage";
import DesignAddPage from "../pages/DesignAddPage";
import MeasurementPage from "../pages/MeasurementPage";
import MeasurementAddPage from "../pages/MeasurementAddPage";
import MeasurementEditPage from "../pages/MeasurementEditPage";
import SmartTrackingPage from "../pages/SmartTrackingPage";
import SmartTrackingAddPage from "../pages/SmartTrackingAddPage";
import SmartTrackingEditPage from "../pages/SmartTrackingEditPage";
import AccountPage from "../pages/Account";
import InvoicePage from "../pages/Account/InvoicePage";
import DownloadReportPage from "../pages/Report/DownloadReportPage";
import DisplayReport from "../pages/Report/DisplayReport";
import StockpileReportPage from "../pages/Report/StockpileReport";
import SupportPage from "../pages/Support/SupportPage";
import LineworkListPage from "../pages/LineworkListPage";
import LineworkCreatePage  from "../pages/LineworkCreatePage";
import Layout from "components/Layout";
import DrawToolContextProvider from "../utils/layers/draw/DrawToolContext";

const RouteList = () => (
  <DrawToolContextProvider>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<PublicRoute element={<LoginPage />} />} />
        <Route path="/signup" element={<PublicRoute element={<SignupPage />} />} />
        <Route path="/displayReport" element={<PublicRoute element={<DisplayReport />} />} />
        <Route path="/stockpileReport" element={<PublicRoute element={<StockpileReportPage />} />} />

        {/* Private Routes */}
        <Route path="/" element={<PrivateRoute element={<Layout />} />}>
          <Route path="project" element={<ProjectPage />} />
          <Route path="flight" element={<FlightPage />} />
          <Route path="flight/add" element={<FlightAddPage />} />
          <Route path="see" element={<SeePage />} />
          <Route path="material" element={<MaterialPage />} />
          <Route path="material/add" element={<MaterialAddPage />} />
          <Route path="material/edit" element={<MaterialEditPage />} />
          <Route path="/export" element={<ExportPage />} />
          <Route path="/design" element={<DesignListPage />} />
          <Route path="/design/add" element={<DesignAddPage />} />
          <Route path="/report" element={<DownloadReportPage />} />
          <Route path="/account" element={<AccountPage />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/account/invoice" element={<InvoicePage />} />
          <Route path="/linework" element={<LineworkListPage />} />
          <Route path="/linework/add" element={<LineworkCreatePage />} />
        </Route>

        {/* Measure Routes */}
        <Route path="/measure" element={<PrivateRoute element={<Layout />} />}>
          <Route path="" element={<MeasurementPage />} />
          <Route path="add" element={<MeasurementAddPage />} />
          <Route path="edit" element={<MeasurementEditPage />} />
        </Route>

        {/* Smart Tracking Routes */}
        <Route path="/smart-tracking" element={<PrivateRoute element={<Layout />} />}>
          <Route path="" element={<SmartTrackingPage />} />
          <Route path="add" element={<SmartTrackingAddPage />} />
          <Route path="edit" element={<SmartTrackingEditPage />} />
        </Route>
      </Routes>
  </DrawToolContextProvider>
);

export default RouteList;
