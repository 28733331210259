import { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";

import FlightDatePicker from "components/Common/DatePicker";
import { selectFlightAction } from "../../redux/slices/flight/selected";
import { getMeasurementListAction } from "../../redux/slices/measurement/list";
import { getStringDateFromDate } from "../../utils/formattings";
import { getIconComponentByType } from "../../utils/assets";



const FlightDatePickertyled = styled(FlightDatePicker)(() => ({
  backgroundColor: "#FFC500", //theme.palette.text.secondary
  borderRadius: "10px",
  width: '40%'
}));


const IconButtonStyled = styled(IconButton)(() => ({
  "&:disabled": {
    color: "#808080",
  },
}));

const FlightSelector = () => {
  const dispatch = useDispatch();

  const selectedFlight = useSelector((state) => state.flightSelected);
  const flights = useSelector((state) =>
    state.flightList.data.map(
      (item, idx) =>
        state.flightList.data[state.flightList.data.length - 1 - idx]
    )
  );

  const flownOnDates = flights.map((flight) =>
    dayjs(new Date(flight.flown_date)).format("MM-DD-YYYY")
  );

  const [previousFlights, setPreviousFlights] = useState([]);
  const [nextFlights, setNextFlights] = useState([]);
  const prevSelectedFlight = useRef(null);

  const handleDateChange = useCallback(
    (newDate) => {
      const currentSelectedFlight = flights.find(
        (flight) =>
          getStringDateFromDate(new Date(newDate)) ===
          getStringDateFromDate(new Date(flight.flown_date))
      );

      if (currentSelectedFlight && currentSelectedFlight !== selectedFlight) {
        dispatch(selectFlightAction({ flight: currentSelectedFlight }));
      }
    },
    [dispatch, flights, selectedFlight]
  );

  useEffect(() => {
    if (
      selectedFlight?.flown_date &&
      selectedFlight !== prevSelectedFlight.current
    ) {
      prevSelectedFlight.current = selectedFlight;

      const currentElementIndex = flownOnDates.findIndex(
        (date) =>
          date === getStringDateFromDate(new Date(selectedFlight.flown_date))
      );

      setPreviousFlights(flights.slice(0, currentElementIndex));
      setNextFlights(flights.slice(currentElementIndex + 1));

      dispatch(getMeasurementListAction());
    }
  }, [selectedFlight, flownOnDates, flights, dispatch]);

  // const handleNext = useCallback(() => {
  //   console.log("Next Flights:", nextFlights);
  //   if (nextFlights.length > 0) {
  //     dispatch(selectFlightAction({ flight: nextFlights[1] }));
  //   } else {
  //     console.warn("No next flights available");
  //   }
  // }, [nextFlights, dispatch]);

  // const handlePrev = useCallback(() => {
  //   console.log("Previous Flights:", previousFlights);
  //   if (previousFlights.length > 0) {
  //     dispatch(
  //       selectFlightAction({
  //         flight: previousFlights[previousFlights.length - 1],
  //       })
  //     );
  //   } else {
  //     console.warn("No previous flights available");
  //   }
  // }, [previousFlights, dispatch]);


  const handleNext = useCallback(() => {
    if (nextFlights.length > 0) {
      dispatch(selectFlightAction({ flight: nextFlights[0] }));
    }
  }, [nextFlights, dispatch]);

  const handlePrev = useCallback(() => {
    if (previousFlights.length > 0) {
      dispatch(
        selectFlightAction({
          flight: previousFlights[previousFlights.length - 1],
        })
      );
    }
  }, [previousFlights, dispatch]);

  return (
    Object.keys(selectedFlight).length !== 0 && (
      <Stack direction="row" spacing={2} alignItems="center">
        <IconButtonStyled
          color="primary"
          disabled={previousFlights.length === 0}
          onClick={handlePrev}
        >
          {getIconComponentByType("arrowBackIos")}
        </IconButtonStyled>
        <FlightDatePickertyled
          // label="Flight Date"
          flownOnDates={flownOnDates}
          disableDates={true}
          // disabled={true}
          showFlightIcon={true}
          value={selectedFlight.flown_date ? dayjs(selectedFlight.flown_date) : null}
          onDateChange={(newDate) => handleDateChange(newDate)}
        />
        <IconButtonStyled
          color="primary"
          disabled={nextFlights.length === 0}
          onClick={handleNext}
        >
          {getIconComponentByType("arrowForwardIos")}
        </IconButtonStyled>
      </Stack>
    )
  );
};

export default FlightSelector;
