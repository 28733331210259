import { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import { getIconComponentByType } from "../../../utils/assets";
import GeoTiff from "./GeoTiff";
import RawFlight from "./RawFlight";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const FlightCreate = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <AppBar position="static" color="secondary">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="disabled tabs example"
          variant="fullWidth"
        >
          <Tab
            iconPosition="start"
            label="Raw Imagery"
            icon={getIconComponentByType("terrain")}
            {...a11yProps(0)}
          />
          <Tab
            iconPosition="start"
            label="GeoTIFF"
            icon={getIconComponentByType("terrain")}
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <RawFlight />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <GeoTiff />
      </TabPanel>
    </>
  );
};

export default FlightCreate;
