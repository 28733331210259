import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { setProjectCreateOpenPopupAction } from "../../../redux/slices/project/createOrEdit";
import Typography from "@mui/material/Typography";
// import { getIconComponentByType } from "../../../utils/assets";
import Button from "../../Common/Button";
import ProjectCard from "../../Project/Card";
import ProjectList from "../../Project/List";
import ProjectCreateOrEdit from "../../Project/CreateOrEdit";
import { setCesiumView, setMapViewAction } from "redux/slices/mapbox/mapSlice";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { IconButton, useMediaQuery, useTheme  } from "@mui/material";
import { resetLineworksaction } from "redux/slices/linework/list";
import { clearPanoAction } from "redux/slices/see/list";
import { resetMeasurementAction } from "redux/slices/measurement/list";
// import { Icon, IconButton } from "@mui/material";


const BoxStyled = styled(Box)(() => ({
  flexGrow: 1,
  marginTop: 4,
  marginBottom: 4,
  display: "inline-flex",
  justifyContent: "space-between",
  margin: 4,
}));

const GridContainerStyled = styled(Grid)(({ theme }) => ({
  //justifyContent: 'space-between',
  alignItems: "center",
  padding: "0px 62px 0px 44px",
  [theme.breakpoints.down("sm")]: {
    padding: 0,
    justifyContent: "center",
  },
}));

const ProjectMainContent = () => {
  const createProjectPopupOpen = useSelector(
    (state) => state.projectCreateOrEdit.createOpenPopup
  );
  const projects = useSelector((state) => state.projectList?.data?.projects);
  const recentProjects = useSelector((state) => state.projectList?.data?.recent_projects);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCesiumView(false));
    dispatch(setMapViewAction({ mapView: false }));
  }, [])

  
  useEffect(() => {
    return () => {
      const map = window?.map;
      dispatch(resetLineworksaction());
      dispatch(clearPanoAction());
      dispatch(resetMeasurementAction());
      if (map) {
        map?.getStyle()?.layers?.forEach(layer => {
          if (layer.id.startsWith('linework') || layer.id.startsWith('pano') || layer.id.startsWith('measure')) {
            map?.removeLayer(layer.id);
          }
        });
     const sources = map.getStyle()?.sources;
      if (sources) {
        Object.keys(sources).forEach(source => {
          if (source.startsWith('linework') || source.startsWith('pano') || source.startsWith('measure')) {
            map.removeSource(source);
          }
        });
      }
      }
    };
  }, []);

  const handleCreateNewProjectClick = () => {
    dispatch(setProjectCreateOpenPopupAction({ openPopup: true }));
  };

  const handleClose = () => {
    dispatch(setProjectCreateOpenPopupAction({ openPopup: false }));
  };

  const handleShowGridView = () => {};
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const projectCount = projects ? projects.length : 0;
  const [visibleProjects, setVisibleProjects] = useState(4);

  useEffect(() => {
    const updateVisibleProjects = () => {
      if (window.innerWidth < 900) { //or theme.breakpoints.values.sm (which is 600)
        setVisibleProjects(2);
      } else {
        setVisibleProjects(4);
      }
    };

    updateVisibleProjects(); 
    window.addEventListener("resize", updateVisibleProjects);

    return () => window.removeEventListener("resize", updateVisibleProjects);
  }, [theme.breakpoints.values.sm]);

  const [startIndex, setStartIndex] = useState(0);

  const handleNext = () => {
    if (startIndex + visibleProjects < recentProjects.length) {
      setStartIndex(startIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  const getHeadersWithIcon = (title, onClick, showIcon = false) => (
    <Grid container alignItems="center">
      <Grid item xs>
        <Typography style={{ fontSize: 20 }}>{title}</Typography>
      </Grid>
      <Grid item>
        {showIcon ? (
          <IconButton></IconButton>
        ) : (
          <Button
            onClick={onClick}
            label="Create New Project"
            sx={{ minWidth: 173, padding: "8px 16px !important" }}
          />
        )}
      </Grid>
    </Grid>
  );

  return (
    <>
      <Box style={{ width: "100%", display: "flex", flexDirection: "column" }} py={2}>
        <Box mb={2}>
          {getHeadersWithIcon("Recent Projects", handleCreateNewProjectClick)}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <IconButton
            onClick={handlePrevious}
            disabled={startIndex === 0}
            sx={{
              color: theme.palette.primary.main,
              fontSize: "4rem",
            }}
          >
            <ChevronLeftIcon fontSize="inherit" />
          </IconButton>
          <Box display="flex" gap={2} flex="1" justifyContent="center">
            {recentProjects?.slice(startIndex, startIndex + visibleProjects).map((project, index) => (
              <Box key={index} flex="0 1 calc(100% / visibleProjects - 16px)">
                <ProjectCard project={project} />
              </Box>
            ))}
          </Box>
          <IconButton
            onClick={handleNext}
            disabled={startIndex + visibleProjects >= recentProjects?.length}
            sx={{
              color: theme.palette.primary.main,
              fontSize: "4rem",
            }}
          >
            <NavigateNextIcon fontSize="inherit" />
          </IconButton>
        </Box>
        <Box mt={3}>
          {getHeadersWithIcon(`All Projects (${projectCount || 0})`, handleShowGridView, true)}
        </Box>
        <Box mt={2}>
          <ProjectList cellContents={projects} />
        </Box>
      </Box>
      <ProjectCreateOrEdit open={createProjectPopupOpen} handleClose={handleClose} />
    </>
  );
};

export default ProjectMainContent;