import {call, put, select} from "redux-saga/effects";
import {
  deleteProjectMaterialRequest,
  editProjectMaterialRequest,
  getCommonMaterialRequest,
  getProjectMaterialListRequest,
  projectMaterialCreateRequest
} from "../requests/materialRequests";
import {setCommonMaterialAction} from "../../slices/material/material";
import {getProjectMaterialListAction, setProjectMaterialListAction} from "../../slices/material/projectMaterial/list";
import {createProjectMaterialSuccessAction} from "../../slices/material/projectMaterial/create";
import {editProjectMaterialSuccessAction} from "../../slices/material/projectMaterial/edit";
import {deleteProjectMaterialSuccessAction} from "../../slices/material/projectMaterial/delete";

export function* getCommonMaterialHandler(){
  try {
    const response = yield call(getCommonMaterialRequest)
    yield put(setCommonMaterialAction({materials: response.data}))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

export function* getProjectMaterialListHandler(){
  try {
    const selectedProjectId = yield select(state => state.selectedProject.id)
    const response = yield call(getProjectMaterialListRequest, selectedProjectId)
    yield put(setProjectMaterialListAction({data: response.data}))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* projectMaterialCreateHandler(action){
  try {
    const payload = action.payload;
    const navigate = action.payload.navigate;
    const selectedProjectId = yield select(state => state.selectedProject.id)

    yield call(projectMaterialCreateRequest, {...payload, project: selectedProjectId})
    yield put(createProjectMaterialSuccessAction())
    navigate("/material")
  } catch (error) {
    yield put(createProjectMaterialSuccessAction());
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* editProjectMaterialHandler(action) {
  try {
    const payload = action.payload;
    yield call(editProjectMaterialRequest, payload.id, payload)
    yield put(editProjectMaterialSuccessAction())
    navigate("/material")
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* deleteProjectMaterialHandler(action){
  try {
    yield call(deleteProjectMaterialRequest, action.payload.id)
    yield put(getProjectMaterialListAction())
    yield put(deleteProjectMaterialSuccessAction())
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}