import { useState } from "react";
import FlightSelector from "../Flight/Selector";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import {
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import AccountCircle from "@mui/icons-material/AccountCircle";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import Tooltip from '@mui/material/Tooltip';
import { logoutAction } from "../../redux/slices/basic/LogoutSlice";
import Loader from "../Common/Loader";

const AccountSection = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "10px 10px",
  borderBottom: "1px solid #ccc",
}));


const MainAppBarContent = () => {
  const selectedFlightName = useSelector((state) => state.flightSelected.name);
  const selectedProjectName = useSelector(
    (state) => state.selectedProject.name
  );
  const currentProject = useSelector((state) => state.selectedProject);

  const name = localStorage.getItem("name");
  const dispatch = useDispatch();
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [userName, setUserName] = useState("");
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState(null);

  const [isLoading, setLoading] = useState(false);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

const handlePorjectDocument = () => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?' + // port
    '(\\/[-a-zA-Z\\d%_.~+:]*)*' + // path
    '(\\?[;&a-zA-Z\\d%_.~+=-@]*)?' + // query string
    '(\\#[-a-zA-Z\\d_]*)?$', 'i' // fragment locator
  );


  if (currentProject?.document) {
    let url = currentProject.document.trim();

    // Prepend protocol if missing
    if (!url.match(/^https?:\/\//)) {
      url = `http://${url}`;
    }

    // Validate the URL
    if (urlPattern.test(url)) {
      window.open(url, '_blank');
    } else {
      console.error('Invalid URL:', url);
      alert('The provided document URL is not valid.');
    }
  }
};


  // useEffect(() => {
  //   const userEmail = localStorage.getItem("userEmail");
  //   const userAuthenticated = localStorage.getItem("isAuthenticated");

  //   if (userEmail && userAuthenticated) {
  //     setIsLoggedIn(true);
  //     // setUserName(localStorage.getItem("name"));
  //   } else {
  //     setIsLoggedIn(false);
  //   }
  // }, []);


  const handleLogoutAndNavigation = () => {
    setLoading(true);

    setTimeout(() => {
      dispatch(logoutAction());
      localStorage.removeItem("token");
      localStorage.removeItem("isAuthenticated");
      navigate("/");
      setLoading(false);
    }, 2000);
  };
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      {isLoading ? <Loader /> : null}
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography color="white">
          Project Name: {selectedProjectName}
        </Typography>

        <Typography color="white">
          Flight Name:{" "}
          {selectedFlightName ? (
            <span>{selectedFlightName}</span>
          ) : (
            "No flight added"
          )}
        </Typography>
        <FlightSelector />
    </Stack>

      <Box sx={{ flexGrow: 0 }} style={{ display: 'flex', marginLeft: 20 }}>
      <Box sx={{ flexGrow: 0 }}>
  { currentProject?.document && <Tooltip title="Project documents" arrow>
          <IconButton onClick={handlePorjectDocument} sx={{ p: 0 }}>
            <FilePresentIcon
              sx={{ width: 40, height: 40, marginRight: '10px', marginTop: '0px'  }}
              style={{ color: "grey" }}
            />
        </IconButton>
      </Tooltip>} 
      </Box>
        <div>
         <Box sx={{ flexGrow: 0 }}>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <AccountCircle
              sx={{ width: 40, height: 40 }}
              style={{ color: "gray" }}
            />
          </IconButton>
          {/* <Typography color="white">
            Welcome: {name} 
          </Typography> */}
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            PaperProps={{
              style: {
                width: 220,
              },
            }}
          >
            <AccountSection>
              <Typography
                style={{
                  marginLeft: 10,
                  fontWeight: 700,
                  fontSize: 12,
                  color: "#5E6C84",
                  marginBottom: 10,
                }}
              >
                Account
              </Typography>
              <div style={{ display: "flex", alignItems: "center" }}>
                <AccountCircle
                  sx={{ width: 40, height: 40 }}
                  style={{ color: "gray" }}
                />
                <Typography style={{ marginLeft: 10, fontWeight: 700 }}>
                  {name}
                </Typography>
              </div>
            </AccountSection>
            <MenuItem
              onClick={handleCloseUserMenu}
              style={{
                padding: "15px 10px",
              }}
            >
              <Typography
                textAlign="center"
                onClick={handleLogoutAndNavigation}
              >
                Sign out
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
        </div>
       
      </Box>
    </Box>
  );
};

export default MainAppBarContent;
