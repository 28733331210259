import { useDispatch, useSelector } from "react-redux";
import {
  loginAction,
  clearServerErrorsAction,
  setLoginLoading,
} from "../../redux/slices/basic/loginSlice";
import BasicLayout from "../Basic/Layout";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  Typography,
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { Link , useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import Loader from "../Common/Loader";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { validationSchema } from "../Common/Validations";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const GridItemStyled = styled(Grid)(() => ({
  width: "100%",
}));

const initialValues = {
  email: "",
  password: "",
};

export default function Login() {
  const dispatch = useDispatch();
  const serverErrors = useSelector((state) => state.login.serverErrors);
  const navigate = useNavigate();
  // const [isLoading, setLoading] = useState(false);
  const isLoading = useSelector((state) => state.login.loading);

  useEffect(() => {
    // Dispatch an action to clear the serverErrors state when the component mounts
    dispatch(clearServerErrorsAction());
  }, [dispatch]);

  // Google Login Success
  // const handleGoogleLoginSuccess = (response) => {
  //   const { accessToken, tokenId } = response;
  //   setLoading(true);
  //   setTimeout(() => {
  //     dispatch(
  //       googleLoginAction({ access_token: accessToken, id_token: tokenId })
  //     );
  //   }, 2000);
  // };

  // //Google Login Failed
  // const handleGoogleLoginFailed = (error) => {
  //   console.log(error);
  //   //setErrors(error);
  // };

  const handleSubmitForm = (data) => {
    // setLoading(true);
    dispatch(setLoginLoading());
    const payloadData = {
      email: data.email,
      password: data.password,
    };
      try {
      dispatch(loginAction({ ...payloadData }));
      navigate("/");

      if (window?.pendo && typeof window.pendo.initialize === 'function') {
        window.pendo.initialize({
          visitor: {
            visitorid: data?.email,
            email: data?.email,
          },
        });
      } else {
        console.error('Pendo is not initialized or pendo.initialize is not a function');
      }
      // setLoading(false);

    } catch (error) {
      console.error('Login error:', error);
      // setLoading(false);
    }
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <BasicLayout
      headerButtonLabel="Sign Up"
      headerButtonLink="/signup"
      heroTextHeading="Welcome Back!"
    >
      {isLoading ? <Loader /> : null}
      {/* {isLoading ? <CircularProgress size={22} /> : null} */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        py={2}
        width="100%"
      >
        {/*<Box pt={2}><Typography>Login with</Typography></Box>*/}
        <Box
          pt={2}
          width="90%"
          px={2}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {/* <GoogleLogin
            style={{
              justifyContent: "center",
              width: "100%",
            }}
            render={(renderProps) => (
              <Button
                type="submit"
                name="Sign In"
                loading={loading && isLoggedIn}
                variant="contained"
                onClick={renderProps.onClick}
                className="signupBtnCls"
              >
                <img src={googleLogo} alt="Sign up with google" />
                <span style={{ marginLeft: 10 }}>Sign in with Google</span>
              </Button>
            )}
            tag="button"
            className="googleButtonCls"
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            onSuccess={handleGoogleLoginSuccess}
            onFailure={handleGoogleLoginFailed}
            //style={{ "font-size": "16px", width: "inherit" }}
          /> */}
        </Box>
        {/* <Box pt={2}>
          <Typography>OR</Typography>
        </Box> */}
      </Box>

      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container direction="column" spacing={2} alignItems="center">
          <GridItemStyled item>
            <Box px={2}>
              <FormControl sx={{ m: 1, width: "95%" }} variant="outlined">
                <InputLabel htmlFor="email">Email</InputLabel>
                <OutlinedInput
                  id="email"
                  {...register("email")}
                  placeholder="email"
                  label="Email"
                  required
                />
                <span className="errorCls">{errors.email?.message}</span>
              </FormControl>
            </Box>
          </GridItemStyled>
          <GridItemStyled item>
            <Box px={2}>
              <FormControl sx={{ m: 1, width: "95%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  {...register("password")}
                  placeholder="password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="password"
                  required
                />
                <span className="errorCls">{errors.password?.message}</span>
              </FormControl>
            </Box>
            {/* <Box px={2}>
              <TextField
                label="Password"
                fullWidth
                required
                type="password"
                name="password"
                // value={values.password}
                // error={errors.password}
                // onChange={handleInputChange}
                id="password"
                {...register("password", {
                  required: "This field is required.",
                })}
              />
              <span className="errorCls">{errors.password?.message}</span>
            </Box> */}
          </GridItemStyled>
          {serverErrors?.non_field_errors ? (
            <GridItemStyled item>
              <Box px={2}>
                <Typography color="error">
                  {serverErrors.non_field_errors}
                </Typography>
              </Box>
            </GridItemStyled>
          ) : null}
          <GridItemStyled item container justifyContent="flex-end">
            <Box px={2}>
              <Grid item>
                {" "}
                <Typography component={Link} to="/reset-password">
                  Forgot Password
                </Typography>
              </Grid>
            </Box>
          </GridItemStyled>
          <GridItemStyled item>
            <Box px={2} pb={2}>
              <Button
                type="submit"
                //onClick={handleSubmit}
                name="Sign In"
                //loading={isLoading && isLoggedIn}
                variant="contained"
                className="mainBtn"
              >
                Sign In
              </Button>

              {/* <LoadingButton
                onclick={handleSubmit}
                loading={loading && isLoggedIn}
               label="Sign In" 
                fullWidth
                sx={{
                  height: "30px",
                }}
              /> */}
            </Box>
            <Box px={2} pb={2} style={{ width: "100%", alignItems: "center" }}>
              <Typography>
                {`Don't have an account?`}{" "}
                <Link to="/signup">
                  <span
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      textDecorationLine: "none",
                    }}
                  >
                    Sign Up
                  </span>
                </Link>
              </Typography>
            </Box>
          </GridItemStyled>
        </Grid>
      </form>
    </BasicLayout>
  );
}