/* eslint-disable react/display-name */
/* eslint-disable unused-imports/no-unused-vars */
import { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Badge } from "@mui/material";
import FlightIcon from "@mui/icons-material/Flight";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
// import TextField from "@mui/material/TextField";

const FlightDatePicker = ({
  flownOnDates = [],
  disableDates = false,
  showFlightIcon = false,
  onDateChange,
  disabled,
  value,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const isFlownDate = (date) =>
    flownOnDates.includes(dayjs(date).format("MM-DD-YYYY"));

  const CustomPickersDay = (props) => {
    const { day, ...other } = props;
    const isFlightDate = isFlownDate(day);

    return (
      <Badge
        key={day.toString()}
        overlap="circular"
        badgeContent={
          showFlightIcon && isFlightDate ? <FlightIcon color="primary" /> :  null
        }
      >
        <PickersDay {...other} day={day} disabled={disableDates && !isFlightDate} />
      </Badge>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value}
        // disabled={disabled}
        onChange={onDateChange}
        onClose={handleClose}
        shouldDisableDate={disableDates ? (date) => !isFlownDate(date) : undefined}
        slots={showFlightIcon ? { day: CustomPickersDay } : undefined}
        // renderInput={(params) => <Button  
        //   {...params}
        //   onClick={handleOpen} 
        //   disabled
        //   onKeyDown={(e) => e.preventDefault()} 
        // />}
        {...props}
        openPickerIcon={<FlightIcon />}

        // open={open}

      />
    </LocalizationProvider>
  );
};

export default FlightDatePicker;




// import { useState, useRef, forwardRef } from "react";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import dayjs from "dayjs";
// import Button from "@mui/material/Button";
// import IconButton from "@mui/material/IconButton";
// import Badge from "@mui/material/Badge";
// import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
// import { PickersDay } from "@mui/x-date-pickers/PickersDay";

// const FlightDatePicker = ({
//   value,
//   onDateChange,
//   flownOnDates = [],
//   disableDates = false,
//   showFlightIcon = false,
//   ...props
// }) => {
//   const [open, setOpen] = useState(false);
//   const buttonRef = useRef(null); 

//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   const isFlownDate = (date) =>
//     flownOnDates.includes(dayjs(date).format("MM-DD-YYYY"));

//   const CustomPickersDay = (props) => {
//     const { day, ...other } = props;
//     const isFlightDate = isFlownDate(day);

//     return (
//       <Badge
//         key={day.toString()}
//         overlap="circular"
//         badgeContent={
//           showFlightIcon && isFlightDate ? (
//             <CalendarTodayIcon color="primary" />
//           ) : null
//         }
//       >
//         <PickersDay {...other} day={day} />
//       </Badge>
//     );
//   };

//   const CustomField = forwardRef(({ onClick }, ref) => (
//     <div
//       ref={ref}
//       style={{ display: "flex", alignItems: "center", gap: "8px" }}
//     >
//       <Button
//         variant="outlined"
//         ref={buttonRef} 
//         onClick={onClick}
//       >
//         {value ? dayjs(value).format("MM/DD/YYYY") : "Select Date"}
//       </Button>
//       <IconButton onClick={onClick}>
//         <CalendarTodayIcon />
//       </IconButton>
//     </div>
//   ));

//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <DatePicker
//         value={value}
//         onChange={(newDate) => {
//           onDateChange(newDate);
//           handleClose(); 
//         }}
//         open={open}
//         onOpen={handleOpen}
//         onClose={handleClose}
//         shouldDisableDate={
//           disableDates ? (date) => !isFlownDate(date) : undefined
//         }
//         PopperProps={{
//           anchorEl: () => buttonRef.current, 
//           placement: "bottom-start", 
//           modifiers: [
//             {
//               name: "offset",
//               options: {
//                 offset: [0, 8], 
//               },
//             },
//           ],
//         }}
//         slots={{
//           field: (props) => <CustomField {...props} />, 
//           ...(showFlightIcon ? { day: CustomPickersDay } : {}),
//         }}
//         {...props}
//       />
//     </LocalizationProvider>
//   );
// };

// export default FlightDatePicker;
